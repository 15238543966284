"use strict";

exports.__esModule = true;
Object.defineProperty(exports, "unstable_batchedUpdates", {
  enumerable: true,
  get: function () {
    return _reactDom.unstable_batchedUpdates;
  }
});

var _reactDom = require("react-dom");