'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports['default'] = freeze;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _deepFreezeStrict = require('deep-freeze-strict');

var _deepFreezeStrict2 = _interopRequireDefault(_deepFreezeStrict);

/**
 * Middleware that prevents state from being mutated anywhere in the app.
 */

function freeze(store) {
  freezeStoreState(store);
  return function (next) {
    return function (action) {
      freezeStoreState(store);
      try {
        return next(action);
      } finally {
        freezeStoreState(store);
      }
    };
  };
}

function freezeStoreState(store) {
  var state = store.getState();
  if (isFreezable(state)) {
    (0, _deepFreezeStrict2['default'])(state);
  }
}

function isFreezable(value) {
  return value !== null && typeof value === 'object';
}
module.exports = exports['default'];